import React from 'react';
import Slide from './slide';
import { titles } from '../text';
import { Box } from '@mui/material/';

class Section extends React.Component {
  render() {
    return <Box className="section fp-auto-height-responsive" id={`${this.props.sectionId}`} data-anchor={this.props.anchorId}>
      <Slide title={titles[this.props.sectionId]} sectionId={this.props.anchorId} />
    </Box>;
  }
}

export default Section;