import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { createRoot } from 'react-dom/client';
import './index.scss';
import Section from './components/section';
import Menu from './components/menu';
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

// NOTE: if using fullpage extensions/plugins put them here and pass it as props.
const pluginWrapper = () => {
  require('./fullpage.parallax.min');
};
const override = {
  position: "absolute"
}
let sections = ['introduction', 'mes-services', 'mes-projets', 'contact', 'credits'];

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      'Arial',
      'sans-serif'
    ].join(','),
    h1: {
      fontSize: '4rem'
    },
    h2: {
      fontSize: '1.1rem'
    }
  },
  palette: {
    primary: {
      main: '#000'
    }
  }
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  componentDidMount() {
    // Need to figure out how component's loading works.
    setTimeout(() => {
      window.addEventListener('load', this.setState({ loading: false }))
    }, '1000');
  }
  render() {
    return (
      this.state.loading ?
        <ClimbingBoxLoader
          size={30}
          cssOverride={override}
          className={"loader"}
          color={"#165d78"}
          speedMultiplier={1.5}
          aria-label="Loading Spinner"
          data-testid="loader"
          loading={this.state.loading} /> :
        <Box className="App">
          <Menu sections={sections} />
          <ReactFullpage
            pluginWrapper={pluginWrapper}
            navigation
            licenseKey={'K294H-IJR3J-03FKK-J202H-WNMLQ'}
            parallax={true}
            parallaxKey={'Q1laR2x1ZEdGdVlYWnZibWN0YTJWMmFXNHVZMjl0cldfNTgxY0dGeVlXeHNZWGc9QXh0'}
            credits={''}
            anchors={sections}
            menu={'#menu'}
            render={comp => console.log('render prop change') || (
              <ReactFullpage.Wrapper>
                {sections.map((section, i) => {
                  return <Section key={`section-${section}`} sectionId={i} anchorId={section} />
                })}
              </ReactFullpage.Wrapper>
            )}
          />
        </Box>
    );
  }
}
const container = document.getElementById('react-root');
const root = createRoot(container);
root.render(
  <ThemeProvider theme={theme}>
    <App tab="home" />
  </ThemeProvider>
);