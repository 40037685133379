import React from 'react';
import { introduction } from '../templates/introduction';
import { mesServices } from '../templates/mes-services';
import { contact } from '../templates/contact';
import { credits } from '../templates/credits';
import MultipleSlides from './multiple-slides';
import { Typography, Box, Grid } from '@mui/material/';

class Slide extends React.Component {
  // Chose the right template.
  renderSwitch(sectionId) {
    switch (sectionId) {
      case 'introduction':
        return introduction;
      case 'mes-services':
        return mesServices;
      case 'contact':
        return contact;
      case 'credits':
        return credits;
      default:
        break;
    }
  }
  render() {
    return this.props.sectionId !== 'mes-projets' ? (
      <Box className="slide" id={`section-${this.props.sectionId}`} data-anchor={this.props.sectionId}>
        <Box className="fp-bg"></Box>
        <Grid container justifyContent="center" alignItems="center">
          <Box className="intro">
            <Typography variant="h1" className="section-title" id={`title-${this.props.sectionId}`}>{this.props.title}</Typography>
            {this.renderSwitch(this.props.sectionId)}
          </Box>
        </Grid>
      </Box>
    ) : <MultipleSlides />
  }
}

export default Slide;