import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import { Grid, TextField, Button, Card, CardContent, Typography, Box, Divider } from '@mui/material/';

export const ContactUs = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_e6om75h', 'template_36zc5eq', form.current, 'QnYpRSMgbhbWzkemn')
      .then((result) => {
        window.location.reload(false);
        alert("J'ai bien reçu votre demande, je vous tiens au courant dès que possible ;)");
      }, (error) => {
        if (window.grecaptcha.getResponse() === "") {
          alert('Veuillez cocher le captcha');
        }
        else if (error.text === 'reCAPTCHA: timeout-or-duplicate') {
          alert('J\'ai déjà reçu votre demande');
        }
        else {
          console.log(error.text);
          alert(`Désolé on règle ça au plus vite. Erreur : ${error.text}`);
        }
      });
  };

  return (
    <Grid container justifyContent="center">
      <Box>
        <Card id="contact-us-card">
          <CardContent>
            <Typography gutterBottom variant="h5">
              Contactez-moi
            </Typography>
            <Divider />
            <Typography sx={{ m: 2 }} component="p" gutterBottom className="contact-form-introduction">
              Promis, je tâcherais de répondre le plus rapidement possible
            </Typography>
            <form component="form" ref={form} onSubmit={sendEmail} id="contact-form">
              <Grid container spacing={1}>
                <Grid xs={12} sm={6} item>
                  <TextField placeholder="Saisissez votre prénom" label="Prénom" name="first_name" variant="outlined" fullWidth required />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <TextField placeholder="Saisissez votre nom" label="Nom" name="last_name" variant="outlined" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <TextField type="email" placeholder="Saisissez votre mail" name="email" label="Email" variant="outlined" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <TextField type="number" placeholder="Saisissez votre numéro de téléphone" name="phone" label="Téléphone" variant="outlined" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Message" multiline rows={4} name="message" placeholder="Saisissez votre message ici." variant="outlined" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <ReCAPTCHA
                    sitekey="6Lc4uq4iAAAAAEkaAinGKqGhQ3RWIeiibhj_etls"
                    onChange={sendEmail}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>Envoyer</Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};

export default ContactUs;