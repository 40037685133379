import { Grid, Typography, Card, CardContent, CardMedia } from '@mui/material/';
export const mesServices = (
  <Grid container justifyContent="center" alignItems="center" spacing={4} my={5}>
    <Grid item xs={12} sm={4} md={4} lg={2}>
      <Card className="my-services-card">
        <CardMedia
          component="img"
          alt="Accompagnement agile"
          height="200"
          image="/images/agile.jpg"
        />
        <CardContent>
          <Typography gutterBottom variant="h2" component="div">
            Un accompagnement agile
          </Typography>
          <Typography className="card-text" variant="body2" color="text.secondary">
            Afin d'améliorer la proactivité et les processus de travail, je vous accompagne
            dans votre intégration agile
          </Typography>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12} sm={4} md={4} lg={2}>
      <Card className="my-services-card">
        <CardMedia
          component="img"
          alt="Aide à la certification PSM I"
          height="200"
          image="/images/certification.jpg"
        />
        <CardContent>
          <Typography gutterBottom variant="h2" component="div">
            Une préparation à la certification PSM I / Initiation Scrum
          </Typography>
          <Typography className="card-text" variant="body2" color="text.secondary">
            Certifié PSM II je vous propose des formations en Scrum pour vous préparez à la certification délivrée par Scrum.org
          </Typography>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12} sm={4} md={4} lg={2}>
      <Card className="my-services-card">
        <CardMedia
          component="img"
          alt="Réalisation d'un site web"
          height="200"
          image="/images/website.jpg"
        />
        <CardContent>
          <Typography gutterBottom variant="h2" component="div">
            Une réalisation de sites vitrines
          </Typography>
          <Typography className="card-text" variant="body2" color="text.secondary">
            Je propose également des services dans la réalisation de vos sites vitrines
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);
